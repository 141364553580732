<template>
   <div class="content">
         <div class="main">
            <div class="main-r">
               <el-tabs v-model="activeName"  @tab-click="handleClick">
                    <el-tab-pane label="用户管理" name="first">
                        <div class="div1">
                            <div class="dropdown">
                                <span class="teshu" @click="btn1" style=" cursor: pointer;"></span>
                                <button class="dropbtn" @click="btn1">
                                    {{info1}}
                                </button>
                                <div class="dropdown-content" v-if="flagSelect">
                                    <a href="#" @click="tap($event)" ref="tapval">全部订单类型</a>
                                    <a href="#" @click="tap($event)">全部购买订单</a>
                                    <a href="#" @click="tap($event)">比价购买订单</a>
                                </div>
                            </div>
                            <input type="text" placeholder="输入产品名称/订单号搜索" class="ipt1">
                            <button class="btn1" >查询</button>
                        </div>
                        <div class="div2">
                            <span class="span1" >
                                产品信息
                            </span>
                            <span class="span2">
                                订单金额
                            </span>
                            <span class="span3">
                                订单状态
                            </span>
                            <span class="span4">
                                交易操作
                            </span>
                        </div>
                        <ul class="ul1">
                            <li>
                                <div class="divt">
                                    <span class="span1">2019-01-01</span>
                                    <span>19-01-01</span>
                                    <span class="span3">订单号：jalhdf666</span>
                                    <span>南京华航商务</span>

                                </div>
                                <div class="divb">
                                    <div class="divb-l">
                                        <div class="divb-l1">
                                            <img src="../../../assets/image/xiaozhutu.jpg" alt="">
                                        </div>
                                        <div class="divb-l2">
                                            <span>此处为产品名称</span>
                                        </div>
                                    </div>
                                    <div class="divb-r">
                                        <div class="divb-rl">
                                            <p>￥200</p>
                                        </div>
                                        <div class="divb-rc">
                                            <p class="p1">待支付</p>
                                            <router-link to="/orderDetail"><p class="p2" >订单详情</p></router-link>
                                        </div>
                                        <div class="divb-rr">
                                            <p class="p1">立即支付</p>
                                            <p class="p2">取消订单</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="divt">
                                    <span class="span1">2019-01-01</span>
                                    <span>19-01-01</span>
                                    <span>订单号：jalhdf666</span>
                                    <span>南京华航商务</span>

                                </div>
                                <div class="divb">
                                    <div class="divb-l">
                                        <div class="divb-l1">
                                            <img src="../../../assets/image/xiaozhutu.jpg" alt="">
                                        </div>
                                        <div class="divb-l2">
                                            <span>此处为产品名称</span>
                                        </div>
                                    </div>
                                    <div class="divb-r">
                                        <div class="divb-rl">
                                            <p>￥200</p>
                                        </div>
                                        <div class="divb-rc">
                                            <p class="p1">待支付</p>
                                            <router-link to="/orderDetail"><p class="p2" >订单详情</p></router-link>
                                        </div>
                                        <div class="divb-rr">
                                            <p class="p1">立即支付</p>
                                            <p class="p2">取消订单</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="divt">
                                    <span class="span1">2019-01-01</span>
                                    <span>19-01-01</span>
                                    <span>订单号：jalhdf666</span>
                                    <span>南京华航商务</span>

                                </div>
                                <div class="divb">
                                    <div class="divb-l">
                                        <div class="divb-l1">
                                            <img src="../../../assets/image/xiaozhutu.jpg" alt="">
                                        </div>
                                        <div class="divb-l2">
                                            <span>此处为产品名称</span>
                                        </div>
                                    </div>
                                    <div class="divb-r">
                                        <div class="divb-rl">
                                            <p>￥200</p>
                                        </div>
                                        <div class="divb-rc">
                                            <p class="p1">待支付</p>
                                            <router-link to="/orderDetail"><p class="p2" >订单详情</p></router-link>
                                        </div>
                                        <div class="divb-rr">
                                            <p class="p1">立即支付</p>
                                            <p class="p2">取消订单</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="divt">
                                    <span class="span1">2019-01-01</span>
                                    <span>19-01-01</span>
                                    <span>订单号：jalhdf666</span>
                                    <span>南京华航商务</span>

                                </div>
                                <div class="divb">
                                    <div class="divb-l">
                                        <div class="divb-l1">
                                            <img src="../../../assets/image/xiaozhutu.jpg" alt="">
                                        </div>
                                        <div class="divb-l2">
                                            <span>此处为产品名称</span>
                                        </div>
                                    </div>
                                    <div class="divb-r">
                                        <div class="divb-rl">
                                            <p>￥200</p>
                                        </div>
                                        <div class="divb-rc">
                                            <p class="p1">待支付</p>
                                            <router-link to="/orderDetail"><p class="p2" >订单详情</p></router-link>
                                        </div>
                                        <div class="divb-rr">
                                            <p class="p1">立即支付</p>
                                            <p class="p2">取消订单</p>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </el-tab-pane>
                    <el-tab-pane label="配置管理" name="second"></el-tab-pane>
                    <el-tab-pane label="角色管理" name="third"></el-tab-pane>
                    <el-tab-pane label="定时任务补偿" name="fourth">

                    </el-tab-pane>
                </el-tabs>


            </div>
        </div>

    </div>

</template>

<script>
    import Vue from 'vue';
    import Aside from "@/components/common/aside.vue";
    import Swiper from 'swiper';
    // import '@/swiper/css/swiper.min.css';
import { log } from 'util';

    Vue.filter("fil",function(info){//过滤
			return "共"+ info +"件产品"; //把item这个原本的info数据加上件产品返回出去
		})


    export default {
        name: "index",
        components: { Aside},//注册组件
        data() {
            return {
                radio1: '上海',
                radio2: '上海',
                num1: 5,
                show:true,
                shows:false,
                info:"20",
                 info1:"全部购买订单",
                flagSelect:false,
                 activeName: 'first',
                tap(event){
            this.info1 = event.target.innerHTML;//获取当前点击元素的内容
             this.flagSelect=false
                }
            }
        },
        methods:{
            showCont(){
                this.show==true,
                this.shows=false
            },
            showConts(){
                this.show==false,
                this.shows=true
            },
            handleClick(){

            },
            btn1(){
                this.flagSelect=true
                console.log(this.flagSelect)
            },
            // orderDetail(){
            //     this.$router.push("/orderDetail")
            // }
        },
        mounted() {
            var galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 4,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            });
            var galleryTop = new Swiper('.gallery-top',{
                spaceBetween: 4,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                thumbs: {
                    swiper: galleryThumbs
                }
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
    @mixin icon($m) {
        $url: '../../../assets/image/' + $m;
        @include bgImage($url);
        background-size: contain;
    }
    *{
        margin:0;
        padding:0;
    }
    h2,ul,li{
        margin:0;
        padding:0
    }
    a{
        text-decoration: none;
    }
    li{
        list-style: none;
     border: 1px solid #e5e5e5;
     margin-top: 20px;
    }
    .content{
        width: 100%;
        height: 1192px;
        background:#f5f5f5;
        button{
            border-color: #F3F3F3;
        }
        .main{
            width: 1200px;
            height:1129px;
            margin:0 auto;
            font-size: 15px;


            .main-l{
                width: 181px;
                height: 1129px;
                background:#fff;
                float: left;

            }
            /deep/ .main-r{
                width: 1000px;
                height: 991px;
                background: #fff;

                // ------以下是头部tab切换导航样式-----
                .el-tabs__nav{
                    margin-left: 20px;
                }
               .el-tabs el-tabs--top{
                   margin-top: 20px;
               }
               .el-tabs__header{
                   margin-top: 20px;
               }
                .el-tabs__item:hover{
                    color:black !important;
                }
                .el-tabs__active-bar{
                    background:#0db168;
                }
                .el-tabs__item.is-active{
                    color:black;
                }
                .el-tabs__item{
                    background: url("../../../assets/image/purchaseOrder-nav.png") no-repeat 0 8px;
                    background-size: 1px 24px;
                    font-size: 16px;
                }
                #tab-first{
                    background: none;
                }
                // ------以上是头部tab切换导航样式-----

                .dropbtn {
                    width: 135px;
                    height: 30px;
                    border:1px solid #e5e5e5;
                   margin-left: 20px;
                    background: #fff;
                    color: fff;
                    font-size: 15px;
                    text-align: center;
                    line-height: 28px;
                    cursor: pointer;

                }

                .dropdown {
                    position: relative;
                    display: inline-block;
                    left: 70px;
                    margin-left: 76px;
                    .span1{
                        position: absolute;
                            left: -50px;
                            top: 5px;
                    }
                    .teshu{
                        display: block;
                        position: absolute;
                        left: 85%;
                        top: 37%;
                        width: 10px;
                        height: 10px;
                     background: url("../../../assets/image/sanjiao.jpg") no-repeat;
                    }
                }
                .top-h2{
                    font-size: 18px;
                    height: 76px;
                    padding-left: 20px;
                    font-weight: 100;
                    line-height: 76px;
                }
                .dropdown1{
                    margin-left:0;
                }

                .dropdown-content {
                    position: absolute;
                    left: 20px;
                    background-color: #f9f9f9;
                    min-width: 134px;
                    text-align: center;
                        z-index: 77;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                }

                .dropdown-content a {
                    font-size: 14px;
                    color: black;

                    text-decoration: none;
                    height: 20px;
                    display: block;
                }

                .dropdown-content a:hover {
                    background-color: #0db168;
                    color:#fff;
                    }

                // .dropdown:hover .dropdown-content {
                //     display: block;
                // }

                .dropbtn{
                    font-size: 14px;
                }
                .dropdown:hover .dropbtn {
                    font-size: 14px;
                }

               .el-tabs__nav-wrap::after{//tab切换下边框样式
                    color: #e5e5e5;
                    height: 1px;
               }
                .ipt1{
                    width: 193px;
                    height: 30px;
                    border: 1px solid #e5e5e5;
                    margin-left: 30px;
                    padding-left:12px;
                    color:#999;
                    font-size: 14px;
                }
                .btn1{
                    width: 46px;
                    height:30px;
                    margin-left: 18px;
                   background: #0db168;
                    border: 0;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 2px;
                }


                .search{//此为搜索样式
                    margin-top:30px;
                    padding-left: 20px;
                }
                .div2{
                    width: 962px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 16px;
                    margin:0 auto;
                    margin-top: 23px;
                    height: 43px;
                    background: #f5f5f5;

                    line-height: 44px;
                    .span1{
                        display:block;
                        float: left;
                        width:244px;
                        text-align: center;
                    }
                    .span2{
                        display: block;
                        float: left;
                        text-align: center;
                        width: 198px;
                    }
                    .span3{
                        margin-left: 66px;
                    }
                    .span4{
                        display: block;
                        float: left;
                        width:113px;
                        text-align: center;
                    }
                    .span5{
                        display: block;
                        float: left;
                        width:153px;
                        text-align: center;
                    }
                    .span6{
                        display: block;
                        float: left;
                        width:86px;
                        text-align: center;

                    }
                }

                  .ul1{
                      width: 962px;
                      margin:0 auto;
                      margin-top: 20px;
                      li{
                          border: 1px solid #e5e5e5;
                          margin-top: 20px;
                        .divt{
                            width: 100%;
                            height: 44px;

                            line-height: 44px;
                            color:#f5f5f5;

                            background: #f5f5f5;
                            span{
                                margin-left: 29px;
                            }
                            .span1{
                            margin-left: 14px;
                            }
                            .span3{
                                text-align: center;
                            }
                        }
                        .divb{
                            width: 100%;
                            height: 120px;


                            .divb-l{
                                width: 244px;
                                height: 120px;

                                float: left;
                                .divb-l2{
                                    font-size: 15px;
                                    height: 200px;

                                }
                                .divb-l1{
                                    width:82px;
                                    height: 82px;
                                    float: left;
                                    margin-top: 20px;
                                    img{
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                ：first-child{
                                    margin-left:13px;
                                }
                                .divb-l1{
                                    img{
                                        display:block;
                                        width: 80px;
                                        height: 80px;
                                    }

                                }
                                .divb-l2{
                                    float: left;
                                    padding-left: 12px;
                                    height: 82px;

                                    span{
                                        display: block;
                                        margin-top: 20px;
                                    }
                                }

                            }
                            .divb-r{
                                float: right;
                                width: 398px;
                                height: 120px;
                                margin-left: 114px;
                                >div{
                                    float: left;
                                    overflow: hidden;
                                }
                                .divb-rl{
                                    width: 174px;
                                    text-align: center;

                                    p{
                                        margin-top: 24px;
                                        text-align: center;
                                    }

                                }
                                .divb-rc{

                                    width: 113px;
                                    .p1{
                                        margin-top: 25px;
                                        text-align: center;
                                    }
                                    .p2{
                                        margin-top: 15px;
                                        color:#343434;
                                        text-align: center;

                                    }
                                }
                                .divb-rr{
                                    width: 111px;
                                    font-size: 15px;
                                    .p1{
                                        margin-top: 18px;
                                        margin-left: 17px;
                                        width: 78px;
                                        height: 26px;
                                        border-radius: 3px;
                                        text-align: center;
                                       background: #0db168;
                                        color: #fff;
                                        text-align: center;
                                        line-height:26px;
                                        text-align: center;
                                    }
                                    .p2{
                                        margin-top: 16px;
                                        text-align: center;
                                    }

                                }

                            }
                        }
                    }
                }



                .div3{
                    padding-left: 30px;
                }
                .div4{
                    width: 960px;
                    border: 1px solid #e5e5e5;
                     margin:0 auto;
                    .div4-1{
                        width: 960px;
                        height:44px;
                        background: #f5f5f5;
                        margin:0 auto;
                        font-size: 15px;
                        line-height: 44px;
                        color: #656565;
                        span{
                            margin-left: 125px;
                        }
                        .span2{
                            margin-left: 250px;
                        }
                        .span3{
                            margin-left: 145px;
                        }
                        .span4{
                            margin-left: 160px;
                        }
                    }
                    .ul2{
                        margin:0 auto;
                        width: 960px;
                            height:120px;

                        li{
                            width: 960px;
                            height:120px;

                            .ul2-lil{
                                width: 327px;
                                height: 100%;
                                // background: blue;
                                float: left;

                            }
                            .ul2-lir{
                                width: 536px;
                                height: 100%;
                                float:right;
                            }
                        }
                    }

                }
                .div5{
                    width: 960px;
                    margin: 0 auto;
                    height:94px;
                    line-height: 94px;
                    text-align: right;
                        span{
                            margin-right: 30px;
                        }
                        .teshu{
                            color:#fe0036;
                        }
                    .btn1{
                         width: 100px;
                        height:36px;

                        border-radius: 3px;
                        border: none;
                        font-size: 15px;
                        color:#fff;
                    }

                }
            }

        }
    }

</style>
